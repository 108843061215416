import React from 'react';

const IconAnchor = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={12}
			height={125}
			viewBox='0 0 12 125'
			fill='none'
		>
			<path
				d='M0.666667 6C0.666667 8.94552 3.05448 11.3333 6 11.3333C8.94552 11.3333 11.3333 8.94552 11.3333 6C11.3333 3.05448 8.94552 0.666667 6 0.666667C3.05448 0.666667 0.666667 3.05448 0.666667 6ZM0.666667 119.5C0.666667 122.446 3.05448 124.833 6 124.833C8.94552 124.833 11.3333 122.446 11.3333 119.5C11.3333 116.554 8.94552 114.167 6 114.167C3.05448 114.167 0.666667 116.554 0.666667 119.5ZM5 6V119.5H7V6H5Z'
				fill='#1D8CC3'
			/>
		</svg>
	);
};

export default IconAnchor;
