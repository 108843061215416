import React from 'react';

const IconCheck = ({ isBlack = false }) => {
    if (isBlack)
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={18} height={14} viewBox="0 0 18 14" fill="none">
                <g clipPath="url(#clip0_131_1061)">
                    <path
                        d="M17.0229 8.94004C16.4509 10.0709 15.5674 10.8976 14.5188 11.5478C13.2745 12.3186 11.9177 12.7503 10.4793 12.9373C9.57198 13.0544 8.66466 13.0684 7.75734 12.9705C6.70192 12.8569 5.68055 12.5912 4.69492 12.1805C4.47703 12.0896 4.29148 12.1158 4.10593 12.2679C3.61227 12.6751 3.11009 13.0719 2.61132 13.4739C2.57047 13.5071 2.52791 13.535 2.48535 13.5648C2.47173 13.5578 2.45811 13.549 2.4445 13.5403C2.5194 13.2834 2.5926 13.0247 2.6675 12.7678C2.77814 12.3885 2.8956 12.0127 3.00285 11.6317C3.06753 11.401 2.97221 11.2332 2.80198 11.0759C2.41556 10.7246 2.01211 10.3837 1.67336 9.98698C0.886902 9.07112 0.432391 7.99971 0.427284 6.76226C0.422177 5.43392 0.934566 4.30833 1.78911 3.33654C2.65728 2.35077 3.72802 1.67437 4.92643 1.19722C6.59978 0.531299 8.33612 0.323309 10.1116 0.491099C11.836 0.653646 13.4549 1.16401 14.9001 2.1725C15.9028 2.87162 16.7199 3.74204 17.1965 4.90433C17.7583 6.27287 17.6902 7.62393 17.0246 8.93829L17.0229 8.94004Z"
                        stroke="black"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M7.91392 9.92062C7.66198 9.9014 7.44579 9.79653 7.3079 9.55183C6.87552 8.78105 6.44144 8.01026 6.00735 7.24122C5.79797 6.86894 5.58518 6.4984 5.3758 6.12612C5.2958 5.9828 5.31282 5.83423 5.41155 5.72587C5.54433 5.5808 5.75371 5.57381 5.88989 5.71888C6.08225 5.92162 6.2678 6.12961 6.45335 6.3376C6.66273 6.56831 6.86871 6.80077 7.07809 7.03148C7.29939 7.27618 7.52579 7.51912 7.74539 7.76557C7.79816 7.82499 7.8288 7.82849 7.87647 7.75858C8.23054 7.24821 8.59653 6.74484 8.97955 6.2572C9.26724 5.88841 9.56173 5.52487 9.86814 5.17181C10.3363 4.63173 10.8163 4.10389 11.3151 3.59528C11.5789 3.32786 11.8479 3.06569 12.1305 2.8175C12.2513 2.71089 12.4301 2.70215 12.5458 2.79478C12.6684 2.89441 12.711 3.08841 12.6327 3.22999C12.5544 3.37156 12.4369 3.48167 12.3365 3.60402C11.8071 4.25421 11.3032 4.92537 10.8265 5.61575C10.3924 6.24672 9.99581 6.9004 9.61791 7.56981C9.25191 8.21825 8.90124 8.87368 8.54887 9.52911C8.41609 9.7773 8.20331 9.90839 7.91051 9.92237L7.91392 9.92062Z"
                        fill="black"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_131_1061">
                        <rect width={18} height={14} fill="white" />
                    </clipPath>
                </defs>
            </svg>
        );

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={18} height={14} viewBox="0 0 18 14" fill="none">
            <g clipPath="url(#clip0_149_740)">
                <path
                    d="M17.0229 8.94004C16.4509 10.0709 15.5674 10.8976 14.5188 11.5478C13.2745 12.3186 11.9177 12.7503 10.4793 12.9373C9.57198 13.0544 8.66466 13.0684 7.75734 12.9705C6.70192 12.8569 5.68055 12.5912 4.69492 12.1805C4.47703 12.0896 4.29148 12.1158 4.10593 12.2679C3.61227 12.6751 3.11009 13.0719 2.61132 13.4739C2.57047 13.5071 2.52791 13.535 2.48535 13.5648C2.47173 13.5578 2.45811 13.549 2.4445 13.5403C2.5194 13.2834 2.5926 13.0247 2.6675 12.7678C2.77814 12.3885 2.8956 12.0127 3.00285 11.6317C3.06753 11.401 2.97221 11.2332 2.80198 11.0759C2.41556 10.7246 2.01211 10.3837 1.67336 9.98698C0.886902 9.07112 0.432391 7.99971 0.427284 6.76226C0.422177 5.43392 0.934566 4.30833 1.78911 3.33654C2.65728 2.35077 3.72802 1.67437 4.92643 1.19722C6.59978 0.531299 8.33612 0.323309 10.1116 0.491099C11.836 0.653646 13.4549 1.16401 14.9001 2.1725C15.9028 2.87162 16.7199 3.74204 17.1965 4.90433C17.7583 6.27287 17.6902 7.62393 17.0246 8.93829L17.0229 8.94004Z"
                    stroke="#1D8CC3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.91392 9.92062C7.66198 9.9014 7.44579 9.79653 7.3079 9.55183C6.87552 8.78105 6.44144 8.01026 6.00735 7.24122C5.79797 6.86894 5.58518 6.4984 5.3758 6.12612C5.2958 5.9828 5.31282 5.83423 5.41155 5.72587C5.54433 5.5808 5.75371 5.57381 5.88989 5.71888C6.08225 5.92162 6.2678 6.12961 6.45335 6.3376C6.66273 6.56831 6.86871 6.80077 7.07809 7.03148C7.29939 7.27618 7.52579 7.51912 7.74539 7.76557C7.79816 7.82499 7.8288 7.82849 7.87647 7.75858C8.23054 7.24821 8.59653 6.74484 8.97955 6.2572C9.26724 5.88841 9.56173 5.52487 9.86814 5.17181C10.3363 4.63173 10.8163 4.10389 11.3151 3.59528C11.5789 3.32786 11.8479 3.06569 12.1305 2.8175C12.2513 2.71089 12.4301 2.70215 12.5458 2.79478C12.6684 2.89441 12.711 3.08841 12.6327 3.22999C12.5544 3.37156 12.4369 3.48167 12.3365 3.60402C11.8071 4.25421 11.3032 4.92537 10.8265 5.61575C10.3924 6.24672 9.99581 6.9004 9.61791 7.56981C9.25191 8.21825 8.90124 8.87368 8.54887 9.52911C8.41609 9.7773 8.20331 9.90839 7.91051 9.92237L7.91392 9.92062Z"
                    fill="#1D8CC3"
                />
            </g>
            <defs>
                <clipPath id="clip0_149_740">
                    <rect width={18} height={14} fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default IconCheck;
