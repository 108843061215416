import React, { useState, useEffect } from 'react';
import IconAdobe from '../../assets/icons/IconAdobe';
import IconGithub from '../../assets/icons/IconGithub';
import { Typography } from 'antd';
import { Collapse, Button, Menu, Dropdown, Space } from 'antd';
import { CaretDownOutlined, CaretUpOutlined, YoutubeOutlined } from '@ant-design/icons';
import { FileOutlined, GithubOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

const HeaderPage = () => {

	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		setIsMobile(window.innerWidth <= 768);
	}, [window.innerWidth]);

	const authors = [
		{ name: "Andy Zou", affiliation: "1,2", bioLink: "https://andyzoujm.github.io/" },
		{ name: "Long Phan", affiliation: 1, bioLink: "https://longphan.ai/" },
		{ name: "Sarah Chen", affiliation: "1,4", bioLink: "https://www.linkedin.com/in/sarah-chen1/" },
		{ name: "James Campbell", affiliation: 7, bioLink: "https://www.linkedin.com/in/jamescampbell57/" },
		{ name: "Phillip Guo", affiliation: 6, bioLink: "https://www.linkedin.com/in/phillip-guo/" },
		{ name: "Richard Ren", affiliation: 8, bioLink: "https://github.com/notrichardren" },
		{ name: "Alexander Pan", affiliation: 3, bioLink: "https://aypan17.github.io/" },
		{ name: "Xuwang Yin", affiliation: 1, bioLink: "https://xuwangyin.github.io/" },
		{ name: "Mantas Mazeika", affiliation: "1,9", bioLink: "https://www.linkedin.com/in/mmazeika/" },
		{ name: "Ann-Kathrin Dombrowski", affiliation: 1, bioLink: "https://scholar.google.com/citations?user=YoNVKCYAAAAJ&hl=en" },
		{ name: "Shashwat Goel", affiliation: 1, bioLink: "https://www.linkedin.com/in/shashwatgoel42?originalSubdomain=in" },
		{ name: "Nathaniel Li", affiliation: "1,3", bioLink: "https://nli0.github.io" },
		{ name: "Michael J. Byun", affiliation: 4, bioLink: "https://www.linkedin.com/in/michael-byun/" },
		{ name: "Zifan Wang", affiliation: 1, bioLink: "https://sites.google.com/west.cmu.edu/zifan-wang/home" },
		{ name: "Alex Mallen", affiliation: 5, bioLink: "https://www.linkedin.com/in/alex-mallen-815b01176" },
		{ name: "Steven Basart", affiliation: 1, bioLink: "https://stevenbas.art/" },
		{ name: "Sanmi Koyejo", affiliation: 4, bioLink: "https://cs.stanford.edu/~sanmi/" },
		{ name: "Dawn Song", affiliation: 3, bioLink: "https://dawnsong.io/" },
		{ name: "Matt Fredrikson", affiliation: 2, bioLink: "https://www.cs.cmu.edu/~mfredrik/" },
		{ name: "Zico Kolter", affiliation: 2, bioLink: "https://zicokolter.com/" },
		{ name: "Dan Hendrycks", affiliation: 1, bioLink: "https://people.eecs.berkeley.edu/~hendrycks/" },
	];

	const affiliations = [
		"Center for AI Safety",
		"Carnegie Mellon University",
		"UC Berkeley",
		"Stanford",
		"EleutherAI",
		"University of Maryland",
		"Cornell University",
		"University of Pennsylvania",
		"University of Illinois Urbana-Champaign",
	];

	let breakAuthorIndices = isMobile ? [17] : [11,16]


	const author_component = <div> <p className='header__member'>
		{authors.map((author, index) => (
			<span key={index}>
				<a href={author.bioLink} target="_blank">{author.name}</a>
				<sup>{author.affiliation}</sup>
				{index !== authors.length - 1 && ", "}
				{breakAuthorIndices.includes(index) && <br />}
			</span>
		))}
	</p>
		<p className='header__note'>
			{affiliations.map((affiliation, index) => (
				<span key={index}>
					<sup>{index + 1}</sup>{affiliation}
					{index !== affiliations.length - 1 && ", "}
					
				</span>
				
			))}
		</p></div>

	const [activeKey, setActiveKey] = useState(['0']);
	const onCollapseChange = (key) => {
		setActiveKey(key);
	};

	const githubLink = "https://github.com/andyzoujm/representation-engineering"
	const paperLink = "https://arxiv.org/abs/2310.01405"



	return (
		<div className='header'>
			<h1 className='header__title'>
				Representation Engineering:<br />
				A Top-Down Approach to AI Transparency
			</h1>

			<Collapse ghost className="custom-collapse" bordered={false} onChange={onCollapseChange}>
				<Panel header={
					<Button ghost className="customDashedButton">
						<div className='header__button' style={{marginTop: "auto", textAlign: 'center !important', color: 'black' }}>
							<span >The RepE Team</span>
							<span style={{ marginLeft: "5px" }}>{activeKey.includes('1') ? <CaretUpOutlined style={{ color: 'black' }} /> : <CaretDownOutlined style={{ color: 'black' }} />}</span>
						</div>

					</Button>
				} key="1" showArrow={false}>
					<div style={{ margin: "0 auto", maxWidth: "1024px" }} >
						{author_component}
					</div>
				</Panel>
			</Collapse>

			<div style={{ alignItems: 'center', textAlign: 'center', marginTop: '20px' }}>
				<Space>
					<Button ghost className='customDashedButton' href={paperLink} target="_blank">
						<span className='header__button' style={{ textAlign: 'center', color: 'black', marginTop: "auto" }}>
							<FileOutlined /> Paper
						</span>
					</Button>
					<Button ghost className='customDashedButton' href={githubLink} target="_blank">
						<span className='header__button' style={{ textAlign: 'center', color: 'black', marginTop: "auto" }}>
							<GithubOutlined /> GitHub
						</span>
					</Button>
					<Button ghost className='customDashedButton' href={"https://www.safe.ai/blog/representation-engineering-a-new-way-of-understanding-models"} target="_blank">
						<span className='header__button' style={{ textAlign: 'center', color: 'black', display: 'inline-flex', alignItems: 'center', justifyContent: 'center', marginTop: "auto" }}>
							<img style={{ width: "18px", marginRight: "5px", alignSelf: 'center' }} src="https://assets-global.website-files.com/63fe96aeda6bea77ac7d3000/63fe9a72750bc67b129df210_CAIS%20Logo.svg" />Blog
						</span>
					</Button>
					{!isMobile && <Button ghost className='customDashedButton' href={"https://www.youtube.com/watch?v=2U5NNiGC9yk&ab_channel=CenterforAISafety"} target="_blank">
						<span className='header__button' style={{ textAlign: 'center', color: 'black', marginTop: "auto" }}>
							<YoutubeOutlined /> Video
						</span>
					</Button>}
				</Space>
				{isMobile && <Button ghost className='customDashedButton' href={"https://www.youtube.com/watch?v=2U5NNiGC9yk&ab_channel=CenterforAISafety"} style={{ marginTop: "2vh" }} target="_blank">
						<span className='header__button' style={{ textAlign: 'center', color: 'black', marginTop: "auto" }}>
							<YoutubeOutlined /> Video
						</span>
				</Button>}
			</div>
		</div>
	);
};

export default HeaderPage;
